import { fromEnv, int, string } from '@/lib/vue-dynamic-config';

export type AppConfig = {
  imagesServerHost: string;
  casinoPlayUrl: string;
  baseUrl: string;
  defaultLocaleId: number;
}

let config: AppConfig | undefined;

export const useConfig = (): AppConfig => {
  if (config === undefined) {
    config = {
      imagesServerHost: fromEnv({
        key: 'VUE_APP_IMAGES_SERVER_HOST',
        parser: string,
      }),
      casinoPlayUrl: fromEnv({
        key: 'VUE_APP_CASINO_PLAY_URL',
        parser: string,
      }),
      baseUrl: fromEnv({
        key: 'VUE_APP_BASE_URL',
        defaultValue: '',
        parser: string,
      }),
      defaultLocaleId: fromEnv({
        key: 'VUE_APP_DEFAULT_LOCALE_ID',
        defaultValue: 9999,
        parser: int,
      }),
    };
  }

  return config;
};
